import {TileTemplatePageDataQuery} from 'gatsby-graphql-types-generated'
import {graphql, navigate} from 'gatsby'
import {useLocation} from '@reach/router'

import Layout from 'src/components/Layout'
import SEO from 'src/components/SEO'
import {Grid, Box} from 'src/components/Box'
import {FlairProvider} from 'src/context/FlairContext'
import ArticleContent from 'src/components/ArticleContent'
import RelatedTiles from 'src/components/RelatedTiles'
import TileSidebar from 'src/components/Tile/Sidebar'
import {isLmsImplementation} from 'src/lib/util'

export const query = graphql`
  query TileTemplatePageData($id: String) {
    contentfulArticleTile(id: {eq: $id}) {
      ...TileContent
      ...RelatedTiles
      ...RelatedJourneys
    }
  }
`

interface Props {
  data: TileTemplatePageDataQuery
}

const TilePage = ({data}: Props) => {
  const currentLocation = useLocation()
  const isLms = isLmsImplementation(currentLocation)
  const tile = data.contentfulArticleTile
  if (!tile) {
    navigate('404')
    return null
  }

  if (isLms) {
    return (
      <FlairProvider seed={parseInt(tile.id, 16)}>
        <Layout>
          <SEO
            title={tile.title}
            description={tile.summary?.summary}
            permalink={tile.permalink}
          />
          <Grid
            gridTemplateColumns={{lg: '1fr'}}
            gridTemplateRows={{lg: 'auto auto'}}
            gridColumnGap="5"
          >
            <Grid gridGap="5">
              <ArticleContent tile={tile} />
            </Grid>
          </Grid>
        </Layout>
      </FlairProvider>
    )
  }

  return (
    <FlairProvider seed={parseInt(tile.id, 16)}>
      <Layout>
        <SEO
          title={tile.title}
          description={tile.summary?.summary}
          permalink={tile.permalink}
        />
        <Grid
          gridTemplateColumns={{lg: '320px 1fr'}}
          gridTemplateRows={{lg: 'auto auto'}}
          gridColumnGap="5"
        >
          <Grid gridGap="5">
            <ArticleContent tile={tile} />
            {/* <TileComments tileId={tile.id} /> */}
          </Grid>

          {/* Sidebar content container */}
          <Box mt={{_: 5, lg: 0}} pt={{lg: 5}} gridRow={{lg: '1 / -1'}}>
            <TileSidebar relatedJourneys={tile.relatedJourneys} />
          </Box>
          <RelatedTiles relatedTiles={tile.relatedTiles} />
        </Grid>
      </Layout>
    </FlairProvider>
  )
}

export default TilePage
