import {Router, RouteComponentProps} from '@reach/router'
import styled from '@emotion/styled'

import {useTileQuery, useTilePreviewQuery} from 'src/graphql-generated'
import Loading from 'src/components/Loading'
import TilePage from 'src/templates/tile'
import Layout from 'src/components/Layout'
import {ExtraSmallBody} from 'src/components/text'

const PreviewNotice = styled.div({
  position: 'fixed',
  bottom: 0,
  right: 0,
  background: 'tomato',
  padding: '0.2rem',
})

const Preview: React.FunctionComponent<RouteComponentProps<{slug: string}>> = ({
  slug,
}) => {
  const tileId = slug?.split('-')[0] || '-1'
  const {data, loading} = useTilePreviewQuery({
    variables: {id: tileId},
  })

  if (loading) {
    return (
      <Layout>
        <Loading size="medium" />
      </Layout>
    )
  }

  return data && data.tilePreview ? (
    <>
      <TilePage data={{cms: {tile: data.tilePreview}} as any} />
      <PreviewNotice>
        <ExtraSmallBody>PREVIEW MODE</ExtraSmallBody>
      </PreviewNotice>
    </>
  ) : (
    <iframe src="/404" title="Not Found" width="100%" />
  )
}

// NOTE: <Preview> and <LiveArticle> are very similar.
// LiveArticle services search results that are not yet built by gatsby
// Preview services admins viewing pre-published articles
export const LiveArticle = ({slug}: {slug: string}) => {
  const tileId = slug?.split('-')[0] || '-1'
  const {data, loading} = useTileQuery({
    variables: {id: tileId},
  })

  if (loading) {
    return (
      <Layout>
        <Loading size="medium" />
      </Layout>
    )
  }

  return data && data.tile ? (
    <TilePage data={{cms: {tile: data.tile}} as any} />
  ) : (
    <iframe src="/404" title="Not Found" width="100%" />
  )
}

const ArticlePreview = () => (
  <Router basepath="/article-preview">
    <Preview path={'/:slug'} />
  </Router>
)

export default ArticlePreview
